import { random } from "remotion";

import {
  ComponentExerciseAudioSequence,
  ComponentExerciseVideo,
  Enum_Componentexercisevideo_Direction,
  UploadFileEntity,
  UploadFileEntityResponse,
  WorkoutMedia
} from "../../../graphql/strapi-cms";
import { secondsToFrames } from "../../../utils/duration";
import { resolveBeginSetAudio, resolveHoldAudio } from "../../audio";
import {
  type AudioSequence,
  type AudioSequenceElement,
  AudioSequenceElementType
} from "../../types";
import { AudioSequencer, CoachingAudioSequencer } from ".";

const BUFFER_SECONDS = 1;

export default class ExerciseLoopAudioSequencer extends AudioSequencer {
  private isFirstSet = false;
  private isLastSetOnSide = false;

  constructor(
    private video: ComponentExerciseVideo,
    private set: number,
    private loopDuration: number,
    private audio: ComponentExerciseAudioSequence,
    private workoutMedia: WorkoutMedia,
    private holdDuration: number,
    private isLastSet: boolean,
    private coachingUsed: AudioSequenceElement[],
    private totalSets: number
  ) {
    super();

    this.video = video;
    this.set = set;
    this.loopDuration = loopDuration;
    this.audio = audio;
    this.workoutMedia = workoutMedia;
    this.isLastSet = isLastSet;
    this.isFirstSet = set + 1 === 1;
    this.isLastSetOnSide =
      this.isLastSet &&
      this.video.direction === Enum_Componentexercisevideo_Direction.Right;
    this.coachingUsed = coachingUsed;
    this.totalSets = totalSets;
  }

  async sequence(): Promise<AudioSequence> {
    const elements: AudioSequenceElement[] = [];

    const firstAudio = await this.buildFirstAudioElement();
    const secondAudio = await this.buildSecondAudioElement();
    const lastAudio = await this.buildLastAudioElement();

    // first audio slot -- reserved for movement audio (first set of non-hold),
    // begin audio (nth set, non-hold), or hold audio (if a hold exercise).
    if (firstAudio) {
      elements.push({
        ...firstAudio,
        startFromFrame: 0
      });

      this.runningDuration +=
        firstAudio.durationInFrames + secondsToFrames(BUFFER_SECONDS);
    }

    // second audio slot -- reserved for breath instructions
    if (
      secondAudio &&
      this.hasEnoughTime(
        secondAudio.durationInFrames,
        lastAudio?.durationInFrames || 0
      )
    ) {
      elements.push({
        ...secondAudio,
        startFromFrame: this.runningDuration
      });

      this.runningDuration += secondAudio.durationInFrames;
    }

    // last audio slot -- reserved for all done or done with a side
    if (
      lastAudio &&
      this.hasEnoughTime(
        lastAudio.durationInFrames,
        lastAudio?.durationInFrames || 0
      )
    ) {
      elements.push({
        ...lastAudio,
        startFromFrame:
          Math.floor(this.loopDuration) - lastAudio.durationInFrames
      });
    }

    const coaching = await this.buildCoachingAudioElements(
      // leave room for done duration, if present
      Math.floor(
        this.loopDuration -
          this.runningDuration -
          (lastAudio?.durationInFrames || 0)
      )
    );

    if (coaching) {
      if (!!lastAudio) elements.splice(elements.length - 1, 0, ...coaching);
      else elements.push(...coaching);
    }

    return {
      elements,
      loopDurationInFrames: this.loopDuration,
      durationInFrames: this.runningDuration
    };
  }

  private async buildFirstAudioElement(): Promise<AudioSequenceElement | null> {
    if (this.isFirstSet && !this.isHold()) {
      return await this.movementAudioElement();
    } else if (!this.hasPositioning() && !this.isHold()) {
      return await this.beginAudioElement();
    }

    if (this.isHold()) {
      return await this.holdAudioElement();
    }

    return null;
  }

  private async buildSecondAudioElement(): Promise<AudioSequenceElement | null> {
    if (!this.hasBreathClips()) return null;

    const clips = this.audio.breathClips?.data as UploadFileEntity[];
    const audioClip =
      clips[this.set - 1] ||
      clips[Math.round(random(clips.length))] ||
      clips[0];

    if (!audioClip.attributes?.url) return null;

    return {
      type: AudioSequenceElementType.Breath,
      durationInFrames: secondsToFrames(
        await this.getAudioDuration(audioClip.attributes.url)
      ),
      audioClip: {
        data: audioClip
      } as UploadFileEntityResponse,
      startFromFrame: 0
    };
  }

  private async buildCoachingAudioElements(
    availableDuration: number
  ): Promise<AudioSequenceElement[] | null> {
    const coachingSequencer = new CoachingAudioSequencer(
      this.audio,
      availableDuration,
      this.coachingUsed,
      this.totalSets
    );

    return await coachingSequencer.sequence(
      this.runningDuration + secondsToFrames(BUFFER_SECONDS)
    );
  }

  private async buildLastAudioElement(): Promise<AudioSequenceElement | null> {
    // position audio is handled by the positioning element
    if (this.hasPositioning()) return null;

    if (this.isLastSetOnSide && this.hasDoneWithRightSideClips()) {
      const clips = this.workoutMedia.doneRightSideAudioClips
        ?.data as UploadFileEntity[];

      const audioClip =
        clips[this.set - 1] ||
        clips[Math.round(random(clips.length))] ||
        clips[0];

      if (!audioClip.attributes?.url) return null;

      return {
        type: AudioSequenceElementType.Done,
        durationInFrames: secondsToFrames(
          await this.getAudioDuration(audioClip.attributes.url)
        ),
        startFromFrame: 0,
        audioClip: {
          data: audioClip
        } as UploadFileEntityResponse
      };
    }

    if (this.isLastSet && this.hasDoneClips()) {
      const clips = this.workoutMedia.doneAudioClips
        ?.data as UploadFileEntity[];
      const audioClip =
        clips[this.set - 1] ||
        clips[Math.round(random(clips.length))] ||
        clips[0];

      if (!audioClip?.attributes?.url) return null;

      return {
        type: AudioSequenceElementType.Done,
        durationInFrames: secondsToFrames(
          await this.getAudioDuration(audioClip.attributes.url)
        ),
        startFromFrame: 0,
        audioClip: {
          data:
            clips[this.set - 1] ||
            clips[Math.round(random(clips.length))] ||
            clips[0]
        } as UploadFileEntityResponse
      };
    }

    return null;
  }

  private hasPositioning() {
    return Number(this.video.positioning?.length) > 0;
  }

  private hasMovementClip() {
    return !!this.audio.movementClip?.data;
  }

  private hasBreathClips() {
    return Number(this.audio.breathClips?.data?.length) > 0;
  }

  private hasDoneClips() {
    return Number(this.workoutMedia.doneAudioClips?.data?.length) > 0;
  }

  private hasDoneWithRightSideClips() {
    return Number(this.workoutMedia.doneRightSideAudioClips?.data?.length) > 0;
  }

  private hasEnoughTime(duration: number, doneDuration: number) {
    return (
      // the running duration plus this element
      duration + this.runningDuration <
      // the total loop duration minus the bit reserved for done
      // if its the last set or last set on a side
      this.loopDuration -
        (this.isLastSet || this.isLastSetOnSide ? doneDuration : 0)
    );
  }

  private isHold() {
    return Number(this.holdDuration) > 0;
  }

  private async movementAudioElement(): Promise<AudioSequenceElement | null> {
    if (!this.hasMovementClip()) return null;

    return {
      type: AudioSequenceElementType.Movement,
      durationInFrames: secondsToFrames(
        await this.getAudioDuration(
          this.audio.movementClip?.data?.attributes?.url as string
        )
      ),
      startFromFrame: 0,
      audioClip: this.audio.movementClip as UploadFileEntityResponse
    };
  }

  private async beginAudioElement(): Promise<AudioSequenceElement | null> {
    const audioClip = resolveBeginSetAudio(
      this.set + 1,
      this.video.direction || Enum_Componentexercisevideo_Direction.Bilateral,
      this.isLastSet,
      this.workoutMedia
    );

    if (!audioClip?.attributes?.url) return null;

    return {
      type: AudioSequenceElementType.Begin,
      durationInFrames: secondsToFrames(
        await this.getAudioDuration(audioClip.attributes.url)
      ),
      startFromFrame: 0,
      audioClip: { data: audioClip } as UploadFileEntityResponse
    };
  }

  private async holdAudioElement(): Promise<AudioSequenceElement | null> {
    const holdClip = resolveHoldAudio(this.holdDuration, this.workoutMedia);

    if (!holdClip) return null;

    return {
      type: AudioSequenceElementType.Hold,
      durationInFrames: secondsToFrames(
        await this.getAudioDuration(holdClip.data?.attributes?.url as string)
      ),
      startFromFrame: 0,
      audioClip: holdClip
    };
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
