import {
  type ComponentExerciseAudioSequence,
  type UploadFileEntity,
  type UploadFileEntityResponse
} from "../../../graphql/strapi-cms";
import { secondsToFrames } from "../../../utils/duration";
import {
  type AudioSequenceElement,
  AudioSequenceElementType
} from "../../types";
import { AudioSequencer } from ".";

export default class CoachingAudioSequencer extends AudioSequencer {
  constructor(
    private audio: ComponentExerciseAudioSequence,
    private totalDuration: number,
    private denylist: AudioSequenceElement[],
    private totalSets: number
  ) {
    super();

    this.audio = audio;
    this.totalDuration = totalDuration;
    this.denylist = denylist;
    this.totalSets = totalSets;
  }

  async sequence(sequenceStartFrom: number): Promise<AudioSequenceElement[]> {
    if (!this.hasCoaching()) {
      return [];
    }

    const coaching = await this.getCoaching();

    if (coaching.length === 0) {
      return [];
    }

    const elements: AudioSequenceElement[] = [];
    const totalClipsDuration = coaching.reduce(
      (acc, clip) => acc + clip.durationInFrames,
      0
    );
    const intervalDuration = Math.abs(
      (this.totalDuration - totalClipsDuration) / coaching.length + 1
    );

    if (coaching.length === 1) {
      const singleClip = coaching[0];
      const startFrame = (this.totalDuration - singleClip.durationInFrames) / 2;

      // put single coaching in the middle
      elements.push({
        ...singleClip,
        startFromFrame: sequenceStartFrom + startFrame
      });
    } else {
      let currentTime = 0;

      // first pass -- sequence audio in even spacing
      for (const clip of coaching) {
        if (
          currentTime + clip.durationInFrames + secondsToFrames(2) >
          this.totalDuration
        ) {
          break;
        }

        // add elements if there is room
        elements.push({
          ...clip,
          startFromFrame: sequenceStartFrom + currentTime
        });
        currentTime += clip.durationInFrames + intervalDuration;
      }
    }

    return elements;
  }

  private async getCoaching(): Promise<AudioSequenceElement[]> {
    const coachingElements = (await this.buildCoachingElements())
      .concat(await this.buildGenericCoachingElements())
      .filter(
        (clip) =>
          !this.denylist.find(
            (e) =>
              e.audioClip.data?.attributes?.url ===
              clip.audioClip.data?.attributes?.url
          )
      );

    // if all coaching clips have been used, force generic coaching clips
    if (coachingElements.length === 0 && this.denylist.length > 0) {
      return await this.buildGenericCoachingElements();
    }

    // if multiple sets and there are 3 coaching clips, only take the first 2
    // to save one for a later set
    if (this.totalSets > 1 && coachingElements.length === 3) {
      return coachingElements.slice(0, 2);
    }

    // only take 3 elements
    return coachingElements.slice(0, 3);
  }

  private async buildCoachingElements(): Promise<AudioSequenceElement[]> {
    return Promise.all(
      this.getCoachingClips().map(
        async (clip) =>
          ({
            type: AudioSequenceElementType.Coaching,
            durationInFrames: secondsToFrames(
              await this.getAudioDuration(clip?.attributes?.url as string)
            ),
            audioClip: {
              data: clip as UploadFileEntity
            } as UploadFileEntityResponse
          }) as AudioSequenceElement
      )
    );
  }

  private async buildGenericCoachingElements(): Promise<
    AudioSequenceElement[]
  > {
    return Promise.all(
      this.getGenericCoachingClips().map(
        async (clip) =>
          ({
            type: AudioSequenceElementType.GenericCoaching,
            durationInFrames: secondsToFrames(
              await this.getAudioDuration(clip?.attributes?.url as string)
            ),
            audioClip: {
              data: clip as UploadFileEntity
            } as UploadFileEntityResponse
          }) as AudioSequenceElement
      )
    );
  }

  private hasCoaching(): boolean {
    return this.getCoachingClips().length > 0;
  }

  private getCoachingClips(): UploadFileEntity[] {
    return this.audio.coachingClips.data || [];
  }

  private getGenericCoachingClips(): UploadFileEntity[] {
    return (this.audio?.genericCoaching?.data || []).map(
      (gc) => gc.attributes?.audioClip.data as UploadFileEntity
    );
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
