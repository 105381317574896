import { getAudioDurationInSeconds } from "@remotion/media-utils";

export default abstract class AudioSequencer {
  protected runningDuration = 0;

  protected isBrowser(): boolean {
    return typeof document !== "undefined";
  }

  /**
   * getAudioDuration
   *
   * This will attempt to resolve the audio duration of a given URL, assuming
   * that it is an audio file. There are no checks at the moment.
   *
   * Its important to note that we resolve audio duration in two different
   * methods, depending on the runtime:
   *
   *  - node: we use fluent-ffmpeg to resolve the duration. this method relies on
   *    ffmpeg being installed on the system and will use ffprobe to parse the metadata
   *    of a file.
   *
   *  - browser: we use the getAudioDurationInSeconds function from @remotion/media-utils
   *    this method will embed an <audio> element in the browser and compute duration
   *    from that.
   *
   * Because we use two different methods, there is no guarantee the duration will
   * be the same between the two. This should be OK for our purposes, as we apply a
   * ceiling to the nearest second anyway and we haven't seen any major discrepancies
   * between the two methods, yet.
   *
   * @param url
   * @returns
   */
  protected async getAudioDuration(url: string) {
    if (!url) throw new Error("getAudioDuration: url is required");

    if (this.isBrowser()) {
      return Math.ceil(await getAudioDurationInSeconds(url));
    } else {
      return await this.getAudioDurationInSecondsFfmpeg(url);
    }
  }

  private async getAudioDurationInSecondsFfmpeg(url: string): Promise<number> {
    const { getAudioDurationInSeconds } = await import(
      /* webpackIgnore: true */ "get-audio-duration"
    );

    try {
      return await getAudioDurationInSeconds(url);
    } catch (error: unknown) {
      // auto retry if we get an error
      if ((error as Error).message === "No duration found!") {
        return await getAudioDurationInSeconds(url);
      }
      return 0;
    }
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
