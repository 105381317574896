import { DEFAULT_FPS } from "../../../globals";
import {
  BodyRegion,
  ExerciseSequence,
  Muscle,
  Skill,
  UploadFileEntityResponse,
  WorkoutMedia
} from "../../../graphql/strapi-cms";
import { resolvePrescriptionAudio } from "../../../services/audio";
import { ExerciseIntroAudioSequencer } from "../../../services/sequencing/audio";
import {
  AudioSequence,
  VideoSequence,
  VideoSequenceElementType
} from "../../../services/types";

export default class ExerciseIntroBuilder {
  constructor(
    private exercise: ExerciseSequence,
    private workoutMedia: WorkoutMedia,
    private sets: number,
    private reps: number,
    private holdDuration: number
  ) {
    this.exercise = exercise;
    this.sets = sets;
    this.reps = reps;
    this.holdDuration = holdDuration;
    this.workoutMedia = workoutMedia;
  }

  async build(): Promise<VideoSequence> {
    const props = await this.getIntroProps();

    const durationInFrames = props.audio.durationInFrames;

    return {
      elements: [
        {
          type: VideoSequenceElementType.ExerciseIntroduction,
          props,
          durationInFrames,
          offsetInFrames: 0
        }
      ],
      durationInFrames,
      offsetInFrames: 0,
      fps: DEFAULT_FPS
    };
  }

  private async getIntroProps() {
    const prescriptionAudio = resolvePrescriptionAudio(
      this.sets,
      this.reps,
      this.holdDuration || 0,
      this.exercise.sequenceType,
      this.workoutMedia
    );

    return {
      ...this.exercise.introduction,
      exerciseName: this.exercise.name as string,
      bodyRegions: this.getBodyRegions(),
      muscles: this.getMuscles(),
      skills: this.getSkills(),
      prescriptionAudioClip: prescriptionAudio,
      audio: await this.getIntroAudioSequence(prescriptionAudio)
    };
  }

  private getBodyRegions(): BodyRegion[] {
    return (this.exercise.bodyRegions?.data || []).map(
      (b) => b.attributes as BodyRegion
    );
  }

  private getMuscles(): Muscle[] {
    return (this.exercise.muscles?.data || []).map(
      (b) => b.attributes as Muscle
    );
  }

  private getSkills(): Skill[] {
    return (this.exercise.skills?.data || []).map((s) => s.attributes as Skill);
  }

  private async getIntroAudioSequence(
    prescriptionAudioClip: UploadFileEntityResponse | undefined
  ): Promise<AudioSequence> {
    const { introduction } = this.exercise;

    if (!introduction) {
      return { elements: [], durationInFrames: 0 };
    }

    const sequencer = new ExerciseIntroAudioSequencer(
      this.exercise,
      prescriptionAudioClip
    );

    return await sequencer.sequence();
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
