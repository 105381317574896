/* eslint-disable react/prop-types */
import "../utils/loadFonts";
import "./../index.css";

import { useApolloClient } from "@apollo/client";
import { Composition } from "remotion";
import { z } from "zod";

import { CmsProvider, EnvProvider } from "../contexts";
import { DEFAULT_FPS } from "../globals";
import {
  PublicationState,
  Transition,
  TransitionWithMediaDocument
} from "../graphql/strapi-cms";
import { secondsToFrames } from "../utils/duration";
import { TransitionComposition } from "./../components/compositions";
import { getSearchParams } from "./../utils/url";

const Schema = z.object({
  slug: z.string().default("grab-your-mat"),
  duration: z.number().default(5)
});

const Video: React.FC = () => {
  const searchParams = getSearchParams();
  const client = useApolloClient();

  return (
    <Composition
      calculateMetadata={async ({ props }) => {
        const { data, error } = await client.query({
          query: TransitionWithMediaDocument,
          variables: {
            slug: props.slug,
            publicationState: PublicationState.Live
          }
        });

        const transition = data?.transitions?.data?.[0]
          ?.attributes as Transition;
        const durationInFrames = secondsToFrames(props.duration);

        if (error || !transition) {
          throw new Error(`Unable to load exercise with slug ${props.slug}`);
        }

        return {
          props: {
            ...props,
            transition
          },
          durationInFrames,
          width: 1920,
          height: 1080,
          fps: DEFAULT_FPS
        };
      }}
      component={TransitionComposition}
      defaultProps={{
        slug: searchParams.slug || "grab-your-mat",
        duration: Number(searchParams.duration) || 5,
        transition: {} as Transition
      }}
      durationInFrames={0}
      fps={DEFAULT_FPS}
      height={1080}
      id="transition"
      schema={Schema}
      width={1920}
    />
  );
};

const TransitionVideo: React.FC = () => (
  <EnvProvider>
    <CmsProvider>
      <Video />
    </CmsProvider>
  </EnvProvider>
);

export { TransitionVideo };

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
