import { preloadAudio, preloadVideo } from "@remotion/preload";

import {
  type ComponentExerciseAudioSequence,
  type ComponentExercisePositioning,
  type ComponentExerciseSetup,
  type ComponentExerciseVideo,
  type ExerciseSequence
} from "../../../graphql/strapi-cms";

export default class ExercisePreloader {
  constructor(
    private exercise: ExerciseSequence,
    private shouldPreloadVideo: boolean = false
  ) {
    this.exercise = exercise;
    this.shouldPreloadVideo = shouldPreloadVideo;
  }

  preload() {
    this.preloadIntro();
    this.preloadSetup();
    this.preloadLoop();
  }

  private preloadIntro() {
    const { introduction } = this.exercise;

    if (introduction?.audioClip?.data?.attributes?.url) {
      preloadAudio(introduction.audioClip.data.attributes.url);
    }
  }

  private preloadSetup() {
    const { setups = [] } = this.exercise;

    (setups as ComponentExerciseSetup[]).map((setup) => {
      const { audioClip, videoClip } = setup;
      if (audioClip.data?.attributes?.url) {
        preloadAudio(audioClip.data.attributes.url);
      }

      if (videoClip.data?.attributes?.url && this.shouldPreloadVideo) {
        preloadVideo(videoClip.data.attributes.url);
      }
    });
  }

  private preloadLoop() {
    const { videoLoops = [] } = this.exercise;

    (videoLoops as ComponentExerciseVideo[]).map((video) => {
      if (video.positioning) {
        this.preloadPositioning(
          video.positioning as ComponentExercisePositioning[]
        );
      }

      if (video.videoClip.data?.attributes?.url && this.shouldPreloadVideo) {
        preloadVideo(video.videoClip.data.attributes.url);
      }

      if (video.audioSequence) {
        this.preloadAudioSequence(video.audioSequence);
      }
    });
  }

  private preloadPositioning(positioning: ComponentExercisePositioning[]) {
    positioning.map((position) => {
      if (position.audioClip.data?.attributes?.url) {
        preloadAudio(position.audioClip.data.attributes.url);
      }
      if (position.videoClip.data?.attributes?.url && this.shouldPreloadVideo) {
        preloadVideo(position.videoClip.data.attributes.url);
      }
    });
  }

  private preloadAudioSequence(audioSequence: ComponentExerciseAudioSequence) {
    if ((audioSequence.breathClips?.data as []).length > 0) {
      audioSequence.breathClips?.data.map((clip) => {
        if (clip.attributes?.url) {
          preloadAudio(clip.attributes.url);
        }
      });
    }

    if ((audioSequence.coachingClips?.data as []).length > 0) {
      audioSequence.coachingClips.data.map((clip) => {
        if (clip.attributes?.url) {
          preloadAudio(clip.attributes.url);
        }
      });
    }

    if (audioSequence.movementClip?.data?.attributes?.url) {
      preloadAudio(audioSequence.movementClip.data.attributes.url);
    }
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
