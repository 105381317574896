/* eslint-disable react/prop-types */
import "./../utils/loadFonts";
import "./../index.css";

import { useApolloClient } from "@apollo/client";
import React, { useCallback } from "react";
import { Composition } from "remotion";
import { z } from "zod";

import { ExerciseComposition } from "./../components/compositions";
import { CmsProvider, EnvProvider } from "./../contexts";
import { DEFAULT_FPS } from "./../globals";
import {
  type ExerciseSequence,
  ExerciseWithMediaDocument,
  PublicationState,
  type WorkoutMedia
} from "./../graphql/strapi-cms";
import { ExerciseBuilder } from "./../services/builders";
import { type VideoSequence } from "./../services/types";
import { getSearchParams } from "./../utils/url";

const Schema = z.object({
  slug: z.string().default("abdominal-drawing-in-maneuver"),
  sets: z.number().default(1),
  reps: z.number().default(10),
  restDuration: z.number().default(10),
  holdDuration: z.number().default(0)
});

const Video: React.FC = () => {
  const searchParams = getSearchParams();
  const client = useApolloClient();
  const createExercise = useCallback(
    async (
      exercise: ExerciseSequence,
      workoutMedia: WorkoutMedia,
      sets: number,
      reps: number,
      restDuration: number,
      holdDuration: number
    ) => {
      const builder = new ExerciseBuilder(
        exercise,
        workoutMedia,
        sets,
        reps,
        restDuration,
        holdDuration
      );

      return await builder.build();
    },
    []
  );

  return (
    <>
      <Composition
        calculateMetadata={async ({ props }) => {
          const { data, error } = await client.query({
            query: ExerciseWithMediaDocument,
            variables: {
              slug: props.slug,
              publicationState: PublicationState.Preview
            }
          });

          if (error || !data.exerciseSequences?.data?.[0]) {
            throw new Error(`Unable to load exercise with slug ${props.slug}`);
          }

          const exercise = data.exerciseSequences?.data?.[0]
            ?.attributes as ExerciseSequence;
          const media = data.workoutMedia?.data.attributes as WorkoutMedia;

          const isHold = Number(exercise.baseHold) > 0;
          const holdDuration = isHold ? props.holdDuration : 0;

          const sequence = await createExercise(
            exercise,
            media,
            Number(props.sets),
            Number(props.reps),
            Number(props.restDuration),
            Number(holdDuration)
          );

          return {
            props: {
              ...props,
              sequence
            },
            durationInFrames:
              sequence.durationInFrames + sequence.offsetInFrames,
            fps: media?.fps || DEFAULT_FPS,
            width: media?.width || 1920,
            height: media?.height || 1080
          };
        }}
        component={ExerciseComposition}
        defaultProps={{
          holdDuration: Number(searchParams.holdDuration) || 10,
          reps: Number(searchParams.reps) || 10,
          restDuration: Number(searchParams.restDuration) || 10,
          sequence: {} as VideoSequence,
          sets: Number(searchParams.sets) || 1,
          slug: getSearchParams().slug || "abdominal-drawing-in-maneuver"
        }}
        durationInFrames={0}
        fps={DEFAULT_FPS}
        height={1080}
        id="exercise"
        schema={Schema}
        width={1920}
      />
    </>
  );
};

const ExerciseVideo: React.FC = () => (
  <EnvProvider>
    <CmsProvider>
      <Video />
    </CmsProvider>
  </EnvProvider>
);

export { ExerciseVideo };

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
