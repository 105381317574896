import React from "react";

const Rest: React.FC<{ className?: string }> = (props) => (
  <svg
    fill="none"
    height={257}
    width={222}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M210.116 78.497a6.174 6.174 0 0 0 4.377-1.809l5.697-5.698a6.198 6.198 0 0 0 0-8.754l-17.39-17.39a6.15 6.15 0 0 0-4.378-1.814 6.152 6.152 0 0 0-4.377 1.813l-5.697 5.697a6.15 6.15 0 0 0-1.813 4.378c0 1.516.546 2.944 1.537 4.073l-6.698 6.698c-15.22-13.03-33.498-21.454-53.118-24.572v-14.1h2.515a6.197 6.197 0 0 0 6.19-6.19V6.189A6.197 6.197 0 0 0 130.771 0h-9.347a3.713 3.713 0 1 0 0 7.428h8.109V19.59H92.467V7.428h12.77a3.713 3.713 0 1 0 0-7.428H91.229a6.197 6.197 0 0 0-6.19 6.19v14.639a6.197 6.197 0 0 0 6.19 6.19h2.515v14.107c-19.615 3.123-37.887 11.55-53.103 24.58l-6.714-6.713a6.143 6.143 0 0 0 1.538-4.073 6.152 6.152 0 0 0-1.813-4.378l-5.697-5.696a6.153 6.153 0 0 0-4.377-1.813 6.15 6.15 0 0 0-4.377 1.813L1.81 62.236a6.198 6.198 0 0 0 0 8.755l5.697 5.698a6.171 6.171 0 0 0 4.377 1.809c1.457 0 2.91-.516 4.073-1.535l7.402 7.401C9.899 102.78 2.625 124.905 2.625 148.15c0 26.804 9.862 52.534 27.768 72.448a3.714 3.714 0 1 0 5.523-4.966c-16.677-18.549-25.862-42.514-25.862-67.481 0-55.672 45.292-100.964 100.964-100.964 55.671 0 100.964 45.292 100.964 100.964 0 55.671-45.293 100.964-100.964 100.964-23.202 0-45.858-8.063-63.796-22.705a3.714 3.714 0 0 0-4.697 5.755c19.26 15.72 43.585 24.377 68.492 24.377 28.953 0 56.172-11.274 76.645-31.747 20.472-20.472 31.747-47.692 31.747-76.644 0-23.253-7.279-45.385-20.748-63.805l7.382-7.382a6.172 6.172 0 0 0 4.073 1.534Zm-11.694-27.523 15.64 15.64-3.946 3.947-15.64-15.641 3.946-3.946ZM7.938 66.614l15.64-15.64 3.947 3.946-15.64 15.64-3.947-3.947ZM27.98 78.48l-6.756-6.755 7.466-7.465 6.473 6.473c-.263.257-.53.51-.79.771a110.696 110.696 0 0 0-6.393 6.976Zm73.192-38.277V27.018h19.657V40.2c-3.244-.289-6.516-.442-9.812-.442-3.308 0-6.591.154-9.845.445Zm86.49 31.301c-.267-.266-.539-.525-.807-.788l6.456-6.456 7.466 7.466-6.737 6.736a111.033 111.033 0 0 0-6.378-6.958Z"
      fill="#fff"
    />
    <path
      d="M111.017 237.72a89.032 89.032 0 0 0 47.032-13.374c.123-.059.245-.119.363-.192.129-.081.25-.17.366-.263 25.105-15.887 41.811-43.899 41.811-75.743 0-49.39-40.182-89.571-89.572-89.571s-89.571 40.182-89.571 89.571c0 49.39 40.182 89.572 89.571 89.572Zm7.866-89.572c0 4.338-3.528 7.867-7.866 7.867-4.338 0-7.866-3.529-7.866-7.867 0-4.337 3.528-7.866 7.866-7.866 4.338 0 7.866 3.529 7.866 7.866ZM64.463 80.51l3.638 5.833a3.711 3.711 0 0 0 5.117 1.186 3.715 3.715 0 0 0 1.187-5.116l-3.645-5.845a81.57 81.57 0 0 1 36.544-10.476v67.22c-6.644 1.664-11.58 7.683-11.58 14.835 0 8.434 6.861 15.295 15.294 15.295 1.639 0 3.217-.262 4.698-.741l10.929 17.527a3.71 3.71 0 0 0 5.117 1.186 3.715 3.715 0 0 0 1.186-5.117l-10.934-17.536a15.236 15.236 0 0 0 3.897-14.078l30.88-15.836a3.716 3.716 0 0 0 1.61-5 3.716 3.716 0 0 0-5-1.61l-30.887 15.84a15.302 15.302 0 0 0-7.782-4.765v-67.22a81.577 81.577 0 0 1 36.544 10.477l-3.644 5.844a3.715 3.715 0 0 0 6.302 3.93l3.638-5.833a82.775 82.775 0 0 1 24.754 26.895l-6.131 3.144a3.715 3.715 0 0 0 3.39 6.61l6.139-3.148a81.56 81.56 0 0 1 7.35 30.424h-6.903a3.713 3.713 0 0 0-3.714 3.714 3.713 3.713 0 0 0 3.714 3.714h6.903a81.575 81.575 0 0 1-7.35 30.424l-6.139-3.148a3.715 3.715 0 0 0-3.39 6.61l6.131 3.144a82.764 82.764 0 0 1-24.754 26.895l-3.638-5.833-.001-.001-12.42-19.919a3.716 3.716 0 0 0-6.304 3.93l16.066 25.764a81.562 81.562 0 0 1-36.543 10.476v-6.903a3.714 3.714 0 1 0-7.428 0v6.903a81.563 81.563 0 0 1-36.544-10.476l3.644-5.844a3.715 3.715 0 0 0-6.303-3.931l-3.638 5.834a82.783 82.783 0 0 1-24.753-26.895l6.131-3.145a3.714 3.714 0 0 0-3.39-6.61l-6.14 3.149a81.566 81.566 0 0 1-7.35-30.425h6.904a3.714 3.714 0 1 0 0-7.427h-6.904a81.57 81.57 0 0 1 7.35-30.425l6.14 3.149a3.715 3.715 0 0 0 3.39-6.61l-6.13-3.145A82.775 82.775 0 0 1 64.462 80.51Z"
      fill="#fff"
    />
  </svg>
);

export { Rest };

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
