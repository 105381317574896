

// noop fns to prevent runtime errors during initialization
if (typeof self !== "undefined") {
	self.$RefreshReg$ = function () {};
	self.$RefreshSig$ = function () {
		return function (type) {
			return type;
		};
	};
}