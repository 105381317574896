import { random } from "remotion";

import {
  Enum_Componentexercisevideo_Direction,
  Enum_Exercisesequence_Sequencetype,
  UploadFileEntityResponse,
  WorkoutMedia
} from "../../graphql/strapi-cms";

const resolvePrescriptionAudio = (
  sets: number,
  reps: number,
  holdDuration: number,
  type: Enum_Exercisesequence_Sequencetype,
  workoutMedia: WorkoutMedia
): UploadFileEntityResponse | undefined => {
  const clips = [
    Enum_Exercisesequence_Sequencetype.BilateralHoldExercise,
    Enum_Exercisesequence_Sequencetype.UnilateralHoldExerciseDifferentSetup,
    Enum_Exercisesequence_Sequencetype.UnilateralHoldExerciseSameSetup,
    Enum_Exercisesequence_Sequencetype.BilateralStretch,
    Enum_Exercisesequence_Sequencetype.UnilateralStretchDifferentSetup,
    Enum_Exercisesequence_Sequencetype.UnilateralStretchSameSetup
  ].includes(type)
    ? workoutMedia.holdPrescriptionAudioClips
    : type === Enum_Exercisesequence_Sequencetype.BilateralExercise
      ? workoutMedia.bilateralPrescriptionAudioClips
      : workoutMedia.unilateralPrescriptionAudioClips;

  if (type === Enum_Exercisesequence_Sequencetype.Transition) return;
  if (!clips?.data) return;

  let found = null;

  if (reps > 0 && holdDuration <= 0) {
    const lookup = `${sets} set${sets > 1 ? "s" : ""} of ${reps}`;
    found = clips.data.find((clip) =>
      clip.attributes?.name?.toLowerCase()?.includes(lookup)
    );
  } else if (holdDuration > 0) {
    const lookup = `${sets} set${sets > 1 ? "s" : ""} with a ${holdDuration}`;

    found = clips.data.find((clip) =>
      clip.attributes?.name?.toLowerCase()?.includes(lookup)
    );
  }

  return { data: found } as UploadFileEntityResponse;
};

const resolveRestAudio = (
  duration: number,
  workoutMedia: WorkoutMedia
): UploadFileEntityResponse | undefined => {
  const lookup = duration === 60 ? "for a minute" : `for ${duration} seconds`;

  const found = workoutMedia.restAudioClips?.data?.find((clip) =>
    clip.attributes?.name?.toLowerCase()?.includes(lookup)
  );

  if (!found) return;

  return { data: found } as UploadFileEntityResponse;
};

const resolveSwitchSideAudio = (
  workoutMedia: WorkoutMedia
): UploadFileEntityResponse | undefined => {
  const found = workoutMedia?.switchSidesAudioClips?.data?.[0];

  if (!found) return;

  return { data: found } as UploadFileEntityResponse;
};

const resolveHoldAudio = (
  holdDuration: number,
  workoutMedia: WorkoutMedia
): UploadFileEntityResponse | undefined => {
  const clips = workoutMedia?.holdAudioClips;

  if (!clips?.data) return;

  const lookup = holdDuration === 60 ? "1 minute" : `${holdDuration} seconds`;
  const found = clips.data.find((clip) =>
    clip.attributes?.name?.toLowerCase()?.includes(lookup)
  );

  if (!found) return;

  return { data: found } as UploadFileEntityResponse;
};

const resolveBeginSetAudio = (
  setNumber: number,
  direction: string,
  isLastSet: boolean,
  workoutMedia: WorkoutMedia
) => {
  const beginClips = workoutMedia?.beginAudioClips?.data || [];
  const beginSetClips = workoutMedia?.beginSetAudioClips?.data || [];

  // depending on the exercise type, we find audio for begin on left/right
  // or generic begin
  const setMap: { [key: number]: string } = {
    2: "second",
    3: "third"
  };

  const lookup =
    isLastSet && Number(setNumber) > 2
      ? "last set"
      : setMap[Number(setNumber)] + " set";
  const sideLookup =
    direction === Enum_Componentexercisevideo_Direction.Right
      ? "right side"
      : direction === Enum_Componentexercisevideo_Direction.Left
        ? "left side"
        : "";

  return (
    beginSetClips.find((bs) =>
      [
        Enum_Componentexercisevideo_Direction.Left,
        Enum_Componentexercisevideo_Direction.Right
      ].includes(direction as Enum_Componentexercisevideo_Direction)
        ? bs.attributes?.name?.toLowerCase()?.includes(lookup) &&
          bs.attributes?.name?.toLowerCase()?.includes(sideLookup)
        : bs.attributes?.name?.toLowerCase()?.includes(lookup) &&
          !bs.attributes?.name?.toLowerCase()?.includes("side")
    ) || beginClips[Math.round(random(beginClips.length))]
  );
};

export {
  resolveBeginSetAudio,
  resolveHoldAudio,
  resolvePrescriptionAudio,
  resolveRestAudio,
  resolveSwitchSideAudio
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
