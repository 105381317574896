import React from "react";
import { Sequence } from "remotion";

import { SCENE_AUDIO_DELAY } from "../../globals";
import {
  type BodyRegion,
  type ComponentExerciseIntroduction,
  type Muscle,
  type Skill,
  type UploadFileEntityResponse
} from "../../graphql/strapi-cms";
import { type AudioSequence } from "../../services/types";
import { Watermark } from "../icons";
import { SceneAudio, SceneBackground } from "../scene";

export type ExerciseIntroduction = ComponentExerciseIntroduction & {
  bodyRegions?: BodyRegion[];
  exerciseName: string;
  muscles?: Muscle[];
  prescriptionAudioClip?: UploadFileEntityResponse;
  skills?: Skill[];
  audio: AudioSequence;
};

const ExerciseIntroduction: React.FC<ExerciseIntroduction> = ({
  bodyRegions = [],
  exerciseName,
  muscles = [],
  skills = [],
  audio = { elements: [] }
}) => {
  const capitalize = (s: string) =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

  return (
    <SceneBackground>
      <h1 className="font-display text-8xl mb-10 leading-tight">
        {exerciseName}
      </h1>
      {bodyRegions.length > 0 && (
        <div className="pb-10 mb-10 border-b border-lynch-400">
          <h3 className="text-lynch-300 font-semibold text-3xl pb-3">
            Body parts
          </h3>
          <p className="font-semibold text-4xl mb-3">
            {capitalize(bodyRegions.map((region) => region.name).join(", "))}
          </p>
        </div>
      )}
      {muscles.length > 0 && (
        <div className="pb-10 mb-10 border-b border-lynch-400">
          <h3 className="text-lynch-300 font-semibold text-3xl pb-3">
            Muscles
          </h3>
          <p className="font-semibold text-4xl mb-3">
            {capitalize(muscles.map((muscle) => muscle.name).join(", "))}
          </p>
        </div>
      )}
      {skills.length > 0 && (
        <div className="">
          <h3 className="text-lynch-300 font-semibold text-3xl pb-3">Skills</h3>
          <p className="font-semibold text-4xl mb-3">
            {capitalize(skills.map((skill) => skill.name).join(", "))}
          </p>
        </div>
      )}
      <Watermark className="absolute right-32 w-[813px] h-[670px]" />
      {audio.elements.map((audio, idx) => (
        <Sequence
          key={idx}
          from={audio.startFromFrame + SCENE_AUDIO_DELAY}
          layout="none"
        >
          <SceneAudio audioClip={audio.audioClip} />
        </Sequence>
      ))}
    </SceneBackground>
  );
};

export { ExerciseIntroduction };

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
