import { DEFAULT_FPS, END_SEQUENCE_DURATION } from "../../../globals";
import {
  type ComponentWorkoutExercise,
  type ExerciseSequence,
  type Workout,
  type WorkoutMedia
} from "../../../graphql/strapi-cms";
import { secondsToFrames } from "../../../utils/duration";
import {
  type VideoSequence,
  type VideoSequenceElement,
  VideoSequenceElementType
} from "../../types";
import ExerciseBuilder from "../exercise/exercise-builder";

export default class WorkoutBuilder {
  workout: Workout;
  workoutMedia: WorkoutMedia;

  constructor(workout: Workout, workoutMedia: WorkoutMedia) {
    this.workout = workout;
    this.workoutMedia = workoutMedia;
  }

  async build() {
    const sequence: VideoSequence = {
      elements: [],
      durationInFrames: 0,
      offsetInFrames: 0,
      fps: DEFAULT_FPS
    };

    const elements = await Promise.all(
      (this.workout?.routine || []).map(
        async (routineComponent) =>
          new Promise(async (resolve) => {
            if (routineComponent?.__typename === "ComponentWorkoutExercise") {
              const exercise = await this.buildExercise(routineComponent);
              resolve(exercise.elements);
            } else if (
              routineComponent?.__typename === "ComponentWorkoutTransition"
            ) {
              resolve({
                type: VideoSequenceElementType.WorkoutTransition,
                props: routineComponent,
                durationInFrames: secondsToFrames(
                  routineComponent.duration || 5
                ),
                offsetInFrames: 0,
                audioFiles: [],
                videoFiles: []
              } as VideoSequenceElement);
            }
          })
      )
    );

    sequence.elements.push(
      ...elements
        .flat()
        .filter((e) => !!e)
        .map((e) => e as VideoSequenceElement)
    );

    sequence.elements.push(this.buildEndSceneElement());

    sequence.durationInFrames = sequence.elements.reduce(
      (acc, element) => acc + element.durationInFrames,
      0
    );

    sequence.offsetInFrames = sequence.elements.reduce(
      (acc, element) => acc + (element.offsetInFrames || 0),
      0
    );

    return sequence;
  }

  public static groupSequence(
    elements: VideoSequenceElement[]
  ): VideoSequenceElement[][] {
    const groups: VideoSequenceElement[][] = [];
    let currentExercise: VideoSequenceElement[] = [];

    elements.forEach((element) => {
      if (
        element.type === VideoSequenceElementType.WorkoutTransition ||
        element.type === VideoSequenceElementType.WorkoutEnd
      ) {
        // exercise group finished -- transition
        if (currentExercise.length > 0) groups.push(currentExercise);
        // transitions and workout end get their own group
        groups.push([element]);
        currentExercise = [];
      } else if (
        currentExercise.length > 0 &&
        element.type === VideoSequenceElementType.ExerciseIntroduction
      ) {
        // exercise group finished -- one exercise to nexzt
        groups.push(currentExercise);
        currentExercise = [element];
      } else {
        currentExercise.push(element);
      }
    });

    return groups;
  }

  private async buildExercise(component: ComponentWorkoutExercise) {
    const builder = new ExerciseBuilder(
      component?.exercise?.data?.attributes as ExerciseSequence,
      this.workoutMedia,
      component.sets,
      component.reps || 0,
      component.restDuration,
      component.holdDuration || 0
    );

    return await builder.build();
  }

  private buildEndSceneElement(): VideoSequenceElement {
    return {
      type: VideoSequenceElementType.WorkoutEnd,
      props: {},
      durationInFrames: secondsToFrames(END_SEQUENCE_DURATION),
      offsetInFrames: 0
    };
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
