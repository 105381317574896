import {
  ComponentExercisePositioning,
  Enum_Componentexercisepositioning_Type,
  Enum_Componentexercisevideo_Direction,
  WorkoutMedia
} from "../../../graphql/strapi-cms";
import { resolveBeginSetAudio } from "../../../services/audio";
import {
  AudioSequence,
  AudioSequenceElementType
} from "../../../services/types";
import { secondsToFrames } from "../../../utils/duration";
import AudioSequencer from "./audio-sequencer";

export default class ExercisePositioningAudioSequencer extends AudioSequencer {
  protected runningDuration = 0;
  protected totalDuration = 0;

  constructor(
    private positioning: ComponentExercisePositioning,
    private direction: Enum_Componentexercisevideo_Direction,
    private workoutMedia: WorkoutMedia
  ) {
    super();

    this.positioning = positioning;
    this.direction = direction;
    this.workoutMedia = workoutMedia;
    this.totalDuration = secondsToFrames(this.positioning.duration);
  }

  async sequence(set: number, isFirstSet = false, isLastSet = false) {
    const sequence: AudioSequence = {
      elements: [],
      durationInFrames: 0
    };

    if (isFirstSet) {
      const element = {
        startFromFrame: this.runningDuration,
        durationInFrames: secondsToFrames(
          await this.getAudioDuration(
            this.positioning.audioClip.data?.attributes?.url ?? ""
          )
        ),
        audioClip: this.positioning.audioClip,
        type: AudioSequenceElementType.Positioning
      };

      sequence.elements.push(element);
      this.runningDuration += element.durationInFrames;
    } else if (
      !isFirstSet &&
      this.positioning.type === Enum_Componentexercisepositioning_Type.In
    ) {
      const audioClip = {
        data: resolveBeginSetAudio(
          set + 1,
          this.direction,
          isLastSet,
          this.workoutMedia
        )
      };

      // when we find a matching set audio clip
      if (audioClip) {
        const element = {
          startFromFrame: this.runningDuration,
          durationInFrames: secondsToFrames(
            await this.getAudioDuration(audioClip.data?.attributes?.url ?? "")
          ),
          audioClip,
          type: AudioSequenceElementType.Positioning
        };

        sequence.elements.push(element);
        this.runningDuration += element.durationInFrames;
      }
    }

    if (
      isLastSet &&
      this.positioning.type === Enum_Componentexercisepositioning_Type.Out
    ) {
      const clip =
        this.direction === Enum_Componentexercisevideo_Direction.Right
          ? this.workoutMedia.doneRightSideAudioClips?.data?.[0]
          : this.workoutMedia.doneAudioClips?.data?.[0];

      if (clip) {
        const element = {
          startFromFrame: this.runningDuration,
          durationInFrames: secondsToFrames(
            await this.getAudioDuration(clip?.attributes?.url ?? "")
          ),
          audioClip: { data: clip },
          type: AudioSequenceElementType.Positioning
        };

        if (this.hasEnoughTime(element.durationInFrames)) {
          sequence.elements.push(element);
          this.runningDuration += element.durationInFrames;
        }
      }
    }

    return sequence;
  }

  private hasEnoughTime(duration: number) {
    const timeLeft = this.totalDuration - this.runningDuration;

    return duration <= timeLeft;
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
