import { DEFAULT_FPS, SCENE_TRANSITION_OFFSET } from "../../../globals";
import {
  ComponentExerciseSetup,
  ComponentExerciseVideo,
  Enum_Exercisesequence_Sequencetype,
  ExerciseSequence,
  WorkoutMedia
} from "../../../graphql/strapi-cms";
import {
  AlternatingExerciseBuilder,
  SequentialExerciseBuilder
} from "../../../services/sequencing";
import {
  VideoSequence,
  VideoSequenceElement,
  VideoSequenceElementType
} from "../../../services/types";
import { secondsToFrames } from "../../../utils/duration";

export default class ExerciseLoopBuilder {
  constructor(
    private exercise: ExerciseSequence,
    private workoutMedia: WorkoutMedia,
    private sets: number,
    private reps: number,
    private restDuration: number,
    private holdDuration?: number
  ) {
    this.exercise = exercise;
    this.sets = sets;
    this.reps = reps;
    this.restDuration = restDuration;
    this.holdDuration = holdDuration;
    this.workoutMedia = workoutMedia;
  }

  async build(): Promise<VideoSequence> {
    const { setups } = this.exercise;

    const elements = await Promise.all(
      setups
        .map((s) => s as ComponentExerciseSetup)
        .sort((s) => (s.direction === "right" ? -1 : 1))
        .map(async (setup, setupIdx) => {
          return new Promise(async (resolve) => {
            const elements: VideoSequenceElement[] = [];
            const setupDuration = secondsToFrames(setup.duration);

            if (!setupDuration)
              throw new Error(
                `Setup for ${this.exercise.slug} duration is missing`
              );

            elements.push({
              type: VideoSequenceElementType.ExerciseSetup,
              props: setup,
              durationInFrames: setupDuration,
              offsetInFrames: SCENE_TRANSITION_OFFSET
            });

            if (this.isSequentialLoop()) {
              const matchingVideo = (this.exercise.videoLoops?.find(
                (vl) => vl?.direction === setup.direction
              ) ||
                this.exercise.videoLoops?.[setupIdx]) as ComponentExerciseVideo;

              const loopElements =
                await this.buildSequentialLoop(matchingVideo);

              elements.push(...loopElements);
            } else if (this.isAlternatingLoop()) {
              const loopElements = await this.buildAlternatingLoop();

              elements.push(...loopElements);
            }

            return resolve(elements);
          });
        })
    );

    const allElements = elements.flat() as VideoSequenceElement[];

    return {
      elements: allElements,
      durationInFrames: allElements.reduce(
        (acc, curr) => acc + curr.durationInFrames,
        0
      ),
      offsetInFrames: 0,
      fps: DEFAULT_FPS
    };
  }

  private isSequentialLoop() {
    return (
      Number(this.exercise.videoLoops?.length) > 0 &&
      [
        Enum_Exercisesequence_Sequencetype.UnilateralExerciseDifferentSetup,
        Enum_Exercisesequence_Sequencetype.UnilateralHoldExerciseDifferentSetup,
        Enum_Exercisesequence_Sequencetype.UnilateralStretchDifferentSetup,
        Enum_Exercisesequence_Sequencetype.BilateralHoldExercise,
        Enum_Exercisesequence_Sequencetype.BilateralStretch,
        Enum_Exercisesequence_Sequencetype.BilateralExercise
      ].includes(this.exercise.sequenceType)
    );
  }

  private isAlternatingLoop() {
    return [
      Enum_Exercisesequence_Sequencetype.UnilateralExerciseSameSetup,
      Enum_Exercisesequence_Sequencetype.UnilateralHoldExerciseSameSetup,
      Enum_Exercisesequence_Sequencetype.UnilateralStretchSameSetup,
      Enum_Exercisesequence_Sequencetype.UnilateralExerciseSameSetupDifferentStartingPosition
    ].includes(this.exercise.sequenceType);
  }

  private async buildSequentialLoop(video: ComponentExerciseVideo) {
    const builder = new SequentialExerciseBuilder(
      this.exercise,
      this.workoutMedia,
      this.sets,
      this.reps,
      this.restDuration,
      this.holdDuration || 0
    );

    return await builder.build(video);
  }

  private async buildAlternatingLoop() {
    const builder = new AlternatingExerciseBuilder(
      this.exercise,
      this.workoutMedia,
      this.sets,
      this.reps,
      this.restDuration,
      this.holdDuration || 0
    );

    return await builder.build();
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
