import {
  type ComponentExerciseVideo,
  Enum_Componentexercisevideo_Direction
} from "../../graphql/strapi-cms";
import { type VideoSequenceElement } from "../types";
import { ExerciseLoopBuilder } from "./exercise-looping";

export default class SequentialExerciseBuilder extends ExerciseLoopBuilder {
  async build(video: ComponentExerciseVideo) {
    const elements: VideoSequenceElement[] = [];
    const direction =
      video.direction || Enum_Componentexercisevideo_Direction.Bilateral;

    for (const set in this.setArray()) {
      const setNum = Number(set);
      const into = this.getIntoPosition(video);
      const outOf = this.getOutOfPosition(video);

      if (into) {
        elements.push(await this.buildPositioning(into, direction, setNum));
      }

      elements.push(
        await this.buildExercise(setNum, video, this.getNumberOfVideoLoops())
      );

      if (outOf) {
        elements.push(
          await this.buildPositioning(
            outOf,
            direction || Enum_Componentexercisevideo_Direction.Bilateral,
            setNum
          )
        );
      }

      if (this.shouldAddRestAfterSet(setNum)) {
        elements.push(this.buildRest());
      }

      if (this.shouldAddSwitchSides(setNum, direction)) {
        elements.push(this.buildSwitchSides());
      }
    }

    return elements;
  }

  private shouldAddRestAfterSet(set: number) {
    return this.restDuration > 0 && set < this.sets - 1;
  }

  private shouldAddSwitchSides(
    set: number,
    direction: Enum_Componentexercisevideo_Direction
  ) {
    return (
      // if there's only one set, we always want to switch sides.
      // otherwise, we only want to switch sides after the last set
      // on a given side. in this case, the set number here is the
      // set in context of the side its on. so, if total sets is
      // 3, then the last set is 3 on the "right" side.
      (this.sets == 1 || set == this.sets - 1) &&
      direction === Enum_Componentexercisevideo_Direction.Right
    );
  }
}

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
